<template>
    <div class="home" v-if="!isLoading">
    <Navbar :user="user" />
    <section class="courses-details-area pt-100 pb-70">
        
        <div class="container">
            <div class="courses-details-header">
                <div class="row justify-content-center align-items-center">
                    <div class="col-md-9 shadow-lg">
                        <div class="courses-title">
                            <h2>{{ course.title }}</h2>
                        </div>
                        <br>
                        <h4 class="title">Lessons</h4>
                        <Accordion :course_content="course_content" />
                    </div>
                </div>
            </div>
        </div>
        
    </section>
    </div>
    <div v-else>
        <Loading />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import axios from 'axios'
import Loading from '@/components/Loading.vue'
import Accordion from '@/components/chapter/Accordion.vue'
export default {
 components: { Navbar, Loading,Accordion },
    data(){
        return {
            rating: 4.3,
            isLoading: true,
            token: localStorage.getItem('user_data'),
            errors: {},
            course: {},
            course_content: {}
        }
    },
    methods:{
        getCourseContent(){
            let id = this.$route.params.id
            axios.get('https://apitraining.vipawaworks.com/api/course_content/get_contents_with_lessons_quizzes/' + id).then(response => {
                this.course_content = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 500);
            })
        },
        getCourses(){
            let id = this.$route.params.id
            axios.get('https://apitraining.vipawaworks.com/api/course/profile/' + id).then(response => {
                this.course = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        },
        getUser(){
            let id = this.$route.params.id
            axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
                this.user = response.data
            }).catch(errors => {
                if (errors.response.status === 401) {
                localStorage.removeItem('user_data')
                this.$router.push({ name: 'Login', query: { redirect: '/courses/chapters/'+ id }})
                }
            })
        },
    },
    created(){
        document.title = `Course Lessons - Training`
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
        this.getUser() 
        this.getCourses()
        this.getCourseContent() 
    }
}
</script>

<style scoped>
.card-img-top{
    height: 100px;
    width: 100px;
}
</style>