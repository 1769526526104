<template>
    <div class="accordion accordion-flush text-start" id="accordionFlushExample">
        <div class="accordion-item" v-for="content in course_content" :key="content.id">
            <h2 class="accordion-header" :id="'flush-heading'+content.id">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#flush-collapse'+content.id" aria-expanded="false" :aria-controls="'flush-collapse'+content.id">
                {{ content.title }}
            </button>
            </h2>
            <div :id="'flush-collapse'+content.id" class="accordion-collapse collapse" :aria-labelledby="'flush-heading'+content.id" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <ol class="list-group list-group-numbered">
                        <li class="list-group-item d-flex justify-content-between align-items-start"  v-for="lesson in content.lessons" :key="lesson.id">
                            <div class="ms-2 me-auto">
                                <router-link :to="{ name: 'CourseLesson', params: {id: lesson.id}}" >
                                {{ lesson.name }} ({{ new Date(lesson.duration  * 1000).toISOString().substr(14, 5)}} min)
                            </router-link>
                            </div>
                        </li>
                         <li class="list-group-item d-flex justify-content-between align-items-start"  v-for="quiz in content.quizzes" :key="quiz.id">
                            <div class="ms-2 me-auto">
                                <router-link :to="{ name: 'CourseQuizDetails', params: {id: quiz.id}}" >
                                    {{ quiz.title }}
                                </router-link>
                            </div>
                        </li>
                    </ol>
                </div> 
            </div>
        </div>
    </div>
</template>

<script>
export default {
props: ['course_content'],
}
</script>

<style>

</style>